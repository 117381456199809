import Image from 'next/image';
import React from 'react';
import { NumberOfHiringPartners } from '@lib/constants';

const whyAlmaData = [
  {
    key: 1,
    subject: 'Placement Support',
    point: 'Complete support from our dedicated placement team until you get a job',
    imagePath: '/assets/chat.png',
  },

  {
    key: 2,
    subject: 'Resume Building',
    point: `Resume sharing in our network of ${NumberOfHiringPartners}+ hiring partners`,
    imagePath: '/assets/resume.png',
  },

  {
    key: 3,
    subject: 'Mock Interviews',
    point: 'Professional mock interviews assistance',
    imagePath: '/assets/grow.png',
  },

  {
    key: 4,
    subject: 'Capstone Projects',
    point: 'Undertake Industry certified Capstone Projects from companies like Zomato & BookMyShow',
    imagePath: '/assets/project.png',
  },

  {
    key: 5,
    subject: '100% Job Assurance',
    point: 'Get all the support to crack your dream tech job.',
    imagePath: '/assets/smily-chat.png',
  },

  {
    key: 6,
    subject: 'Growth Champion',
    point: `Know learning strategies and get mentorship from our top Alumni`,
    imagePath: '/assets/smily-chat.png',
  },
];

function WhyAlmaBetter() {
  return (
    <div
      className="module flex flex-col items-center gap-y-[64px] bg-[#F9FAFB] px-[16px] pt-[32px] pb-[44px] md:px-0 md:pt-[80px] md:pb-[124px]"
      id="Why AlmaBetter?"
    >
      <div className="flex flex-col items-center gap-y-[20px]">
        <h2 className="font-satoshi-medium font-satoshi-medium text-[30px] font-semibold font-bold leading-9 md:font-satoshi-bold md:font-satoshi-bold md:text-[36px]  md:leading-none">
          Why Choose{' '}
          <span className="relative -top-2 text-red underline underline-offset-8">AlmaBetter?</span>
        </h2>
        <p className="font-satoshi-regular text-[14px] leading-[20px] text-gray-navigation md:text-[18px] md:leading-7">
          AlmaBetter offers extensive list of services to help you become Job ready.
        </p>
      </div>

      <div className="grid gap-x-[32px] gap-y-[64px] md:w-5/6 md:grid-cols-3">
        {whyAlmaData.map((almaPoint) => (
          <FeatureCard key={almaPoint.key} {...almaPoint} />
        ))}
      </div>
    </div>
  );
}

export default WhyAlmaBetter;

function FeatureCard({ imagePath, subject, point }) {
  return (
    <div className="flex flex-col items-center gap-y-[20px]">
      <span
        className="flex items-center justify-center rounded-[10px] border border-gray-2 bg-white p-[20px]"
        style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
      >
        <Image src={imagePath} width={24} height={24} objectFit="contain" />
      </span>
      <div className="flex flex-col items-center gap-y-[8px]">
        <h3 className="text-center font-satoshi-bold text-[20px] leading-[30px]">{subject}</h3>
        <p className="text-center font-satoshi-regular text-[16px] leading-6 text-gray-navigation">
          {point}
        </p>
      </div>
    </div>
  );
}
